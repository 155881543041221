/* .text {font-family: 'Segoe UI'; font-size: medium} */
.text {font-family: "Segoe UI"; font-size: small}
.green {font-family: "Segoe UI"; font-size: medium; color: rgb(0, 200, 0);}
.green2 {font-family: "Segoe UI"; font-size: medium; color: rgb(0, 176, 80);}
.blue {font-family: "Segoe UI"; font-size: medium; color: rgb(0, 0, 255);}
.red {font-family: "Segoe UI"; font-size: medium; color: rgb(255, 0, 0); }
.black {font-family: "Segoe UI"; font-size: medium; color: rgb(0, 0, 0); }
.pink {font-family: "Segoe UI"; font-size: medium; color: rgb(240, 17, 151);}
.grey {font-family: "Segoe UI"; font-size: smaller; color: rgb(151, 151, 151)}
.pprecord {cursor:pointer; border: 1px solid transparent; text-align: left;}
.pprecord:hover {font-family: "Segoe UI"; font-size: medium; color: hsl(300, 4%, 5%); background-color: #EFEFEF; text-align: left;}
.asmshover {cursor:pointer; border: 1px solid transparent;}
.asmshover:hover {font-family: "Segoe UI"; color: hsl(0, 0%, 5%); background-color: #e7e4e4; }
.scorecardhover {font-family: "Segoe UI"; font-size: small; cursor:pointer; border: 1px solid transparent;}
.scorecardhover:hover {font-family: "Segoe UI"; font-size: x-small; color: hsl(0, 0%, 5%); background-color: #EFEFEF;}
.taskrecordshover {font-family: "Segoe UI"; cursor:pointer; font-size: 16px; border: 1px solid transparent;}
.taskrecordshover:hover {font-family: "Segoe UI";  cursor:pointer; font-size: 16px;  background-color: #f1f1f1; border: 1px solid #336791; border-radius: 3px; cursor:pointer}